import rbc from 'react-big-calendar/lib/css/react-big-calendar.css'
import rc from 'react-dates/lib/css/_datepicker.css'
import tm from 'rc-time-picker/assets/index.css'
import search from '../styles/search.scss'
import login from '../styles/login.scss'
import get_in from '../styles/get-in.scss'
import login_signup from '../styles/login-signup.scss'
import login_signup_responsive from '../styles/login-signup-responsive.scss'
import logged from '../styles/logged.scss'
import profile from '../styles/profile.scss'
import profile_responsive from '../styles/profile-responsive.scss'
import sched_pay from '../styles/sched_pay.scss'
import sched_pay_responsive from '../styles/sched_pay-responsive.scss'
import panel_patient from '../styles/panel-patient.scss'
import panel_patient_responsive from '../styles/panel-patient-responsive.scss'
import panel_psycho from '../styles/panel-psycho.scss'
import panel_psycho_responsive from '../styles/panel-psycho-responsive.scss'
import psycho_profile from '../styles/psycho-profile.scss'
import sign_up from '../styles/sign-up.scss'

export default [
  rc,
  rbc,
  tm,
  login,
  get_in,
  search,
  login_signup,
  login_signup_responsive,
  profile,
  profile_responsive,
  sched_pay,
  sched_pay_responsive,
  panel_patient,
  panel_patient_responsive,
  panel_psycho,
  panel_psycho_responsive,
  sign_up,
  logged,
  psycho_profile
]
